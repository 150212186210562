import React from 'react'
import styled from 'styled-components'
import { GRAY } from '../Utils'

// https://codepen.io/alexnewman/pen/ByePod

const MenuIcon = ({ open }: { open: boolean }) => {
  return (
    <Styled>
      <button className={`menu ${open ? 'active' : ''}`} type="button">
        <span className="bar"></span>
      </button>
    </Styled>
  )
}

const Styled = styled.div`
  /* font-size: 1em; */

  .menu {
    cursor: pointer;
    font-size: 0.48em;
    transition: 0.1s transform linear;
    position: relative;
    background: 0;
    float: left;
    height: 2.7em;
    width: 3.5em;
    z-index: 1;
    outline: 0;
    padding: 0;
    border: 0;
  }

  .bar,
  .bar::before,
  .bar::after {
    transition: 0.2s background linear 0.1s, 0.2s top linear 0.2s,
      0.2s transform linear;
    position: absolute;
    background: ${GRAY};
    margin: auto;
    width: 100%;
    height: 0.3em;
    content: '';
    top: 50%;
    left: 0;
  }
  .bar {
    margin-top: -0.2em;
  }
  .bar::before {
    top: -1.2em;
  }
  .bar::after {
    top: 1.2em;
  }
  .bar::before,
  .bar::after {
    transform: rotate(0deg);
  }
  .active .bar {
    background: 0;
  }
  .active .bar::before {
    transform: rotate(45deg);
  }
  .active .bar::after {
    transform: rotate(-45deg);
  }
  .active .bar::before,
  .active .bar::after {
    top: 0;
  }
  .active .bar,
  .active .bar::before,
  .active .bar::after {
    transition: 0.2s background linear 0.1s, 0.2s top linear,
      0.2s transform linear 0.2s;
  }
  nav {
    width: 250px;
    height: 100%;
    background: #eaeaea;
    position: fixed;
    transform: translateX(-250px);
    transition: transform 0.3s cubic-bezier(0.56, 0.1, 0.34, 0.91);
    padding-top: 6.2em;
  }
`

export default MenuIcon

import React from 'react'
import styled from 'styled-components'

const Logo = ({ version = 'icon' }: { version?: 'full' | 'icon' }) => (
  <Styles>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      x="0px"
      y="0px"
      viewBox={`0 0 ${version === 'icon' ? 429.49 * 0.36 : 429.49} 140.71`}
    >
      <g>
        <polygon
          className="st0"
          points="40.93,138.01 5.66,90.44 22.54,31.12 78.84,4.72 132.17,31.12 142.38,90.44 101.77,138.01 		"
        />
        <polygon
          className="st1"
          points="40.96,138.01 14.59,87.41 46.65,36.8 105.08,36.8 131.45,87.41 99.39,138.01 		"
        />
        <polygon
          className="st2"
          points="41.01,138.01 26.97,85.75 73.24,53.45 115.88,85.75 95.96,138.01 		"
        />
        <polygon
          className="st3"
          points="93.39,138.01 41.03,138.01 43.97,85.65 96.33,85.65 		"
        />
        <path
          className="st4"
          d="M47.4,110.02c-6.2,5.86-7.19,15.06-2.67,21.49l24.24-22.95C62.82,103.67,53.59,104.15,47.4,110.02z
			 M69.29,133.22c6.2-5.87,7.19-15.06,2.67-21.49l-24.25,22.95C53.87,139.56,63.1,139.08,69.29,133.22z"
        />
        <path
          className="st5"
          d="M150.36,33.75l-15.3,14.49l0.2,1.13l15.76-14.92L150.36,33.75z M104.5,77.17l0.77,0.58l14.12-13.37
			l-0.46-0.88L104.5,77.17z"
        />
        <path
          className="st5"
          d="M41.11,137.29l-0.04,0.72h0.66l54.53-51.63l0.04-0.74h-0.65L41.11,137.29z"
        />
      </g>
      {version === 'full' && (
        <g>
          <path
            className="st6"
            d="M168.85,113.77c-3.74-1.98-7.13-2.9-10.69-2.9c-7.48,0-13.11,5.68-13.11,13.29c0,7.75,5.59,13.2,13.42,13.2
			c3.39,0,6.6-0.92,10.69-3.08v2.42c-3.48,1.85-7.17,2.82-10.65,2.82c-9.15,0-15.97-6.56-15.97-15.36c0-9.2,7.52-15.45,15.75-15.45
			c3.34,0,6.91,0.84,10.56,2.51V113.77z"
          />
          <path className="st6" d="M175.93,139.12h-2.33V109.1h2.33V139.12z" />
          <path
            className="st6"
            d="M204.36,111.04h-11.27v28.08h-2.33v-28.08H179.5v-1.94h24.86V111.04z"
          />
          <path
            className="st6"
            d="M218.53,123.01v16.11h-2.33v-16.11l-11.66-13.91h2.82l10.03,11.88l9.99-11.88h2.82L218.53,123.01z"
          />
          <path
            className="st6"
            d="M244.18,109.1c12.76,0,16.37,8.41,16.37,15.01c0,6.95-4.09,15.01-15.97,15.01h-10.91V109.1H244.18z
			 M235.99,137.18h8.45c7.22,0,13.6-4.62,13.6-13.07c0-7.7-5.59-13.07-13.73-13.07h-8.32V137.18z"
          />
          <path
            className="st6"
            d="M282.63,111.04H268.6v12.01h13.69v1.94H268.6v12.19h14.39v1.94h-16.72V109.1h16.37V111.04z"
          />
          <path
            className="st6"
            d="M301.91,113.15c-3.08-1.76-4.67-2.29-6.73-2.29c-3.61,0-6.34,2.46-6.34,5.72c0,8.01,14.3,4.18,14.3,14.7
			c0,4.62-3.83,8.23-8.71,8.23c-2.46,0-5.06-0.88-7.7-2.64v-2.73c3.34,2.42,5.24,3.21,7.57,3.21c3.56,0,6.34-2.6,6.34-5.98
			c0-8.54-14.3-4.8-14.3-14.7c0-4.44,3.87-7.96,8.62-7.96c2.33,0,4.62,0.62,6.95,1.89V113.15z"
          />
          <path className="st6" d="M310.7,139.12h-2.33V109.1h2.33V139.12z" />
          <path
            className="st6"
            d="M342.7,113.9c-3.65-2.07-7.04-3.04-10.65-3.04c-7.61,0-13.25,5.63-13.25,13.25c0,6.73,4.44,13.25,13.91,13.25
			c3.48,0,5.85-0.66,8.23-1.85v-8.1h-6.34v-1.94h8.67v11.49c-3.56,1.72-7.17,2.55-10.65,2.55c-9.33,0-16.33-6.6-16.33-15.49
			c0-8.8,6.82-15.31,16.11-15.31c3.39,0,6.07,0.66,10.3,2.55V113.9z"
          />
          <path
            className="st6"
            d="M376.22,139.12h-1.94l-22.8-26.23v26.23h-2.33V109.1h1.98l22.75,26.23V109.1h2.33V139.12z"
          />
          <path
            className="st6"
            d="M399.54,111.04H385.5v12.01h13.69v1.94H385.5v12.19h14.39v1.94h-16.72V109.1h16.37V111.04z"
          />
          <path
            className="st6"
            d="M412.39,109.1c7.35,0,9.95,3.74,9.95,7.75c0,3.48-1.89,5.9-5.72,7.31c1.41,0.75,2.6,2.46,4,4.58l6.78,10.39
			h-2.73l-5.24-8.23c-3.61-5.68-4.75-6.16-8.36-6.16h-4.05v14.39h-2.33V109.1H412.39z M407.02,122.79h4.97
			c5.28,0,7.83-2.29,7.83-5.9c0-3.39-2.42-5.85-7.92-5.85h-4.88V122.79z"
          />
        </g>
      )}{' '}
    </svg>
  </Styles>
)

export default Logo

const Styles = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  svg {
    width: 100%;
  }

  .st0 {
    fill: #e4f0f9;
  }
  .st1 {
    fill: #c2dff1;
  }
  .st2 {
    fill: #98ccea;
  }
  .st3 {
    fill: #5db7e1;
  }
  .st4 {
    fill: #009dd5;
  }
  .st5 {
    fill: #0093d0;
  }
  .st6 {
    fill: #a7a9ac;
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    svg {
      height: 4rem;
    }
  }
`

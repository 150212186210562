import React, { ReactNode, useEffect } from 'react'
import './base.css'
import './normalize.css'
import Navigation from './navigation'
import styled from 'styled-components'
import {
  MOBILE_BREAKPOINT,
  DEFAULT_SIZE,
  MOBILE_SIZE,
  LARGE_BREAKPOINT,
  XL_BREAKPOINT,
} from '../Utils'
import Logo from './logo'

declare const __PREFIX_PATHS__: string
declare const __PATH_PREFIX__: string

const Template = ({
  location,
  children,
}: {
  location: Location
  children: ReactNode
}) => {
  useEffect(() => {
    //Required to load embedded content with markdown files
    ;(function (w, d) {
      var id = 'embedly-platform',
        n = 'script'
      if (!d.getElementById(id)) {
        w.embedly =
          w.embedly ||
          function () {
            ;(w.embedly.q = w.embedly.q || []).push(arguments)
          }
        var e = d.createElement(n)
        e.id = id
        e.async = 1
        e.src =
          ('https:' === document.location.protocol ? 'https' : 'http') +
          '://cdn.embedly.com/widgets/platform.js'
        var s = d.getElementsByTagName(n)[0]
        s.parentNode.insertBefore(e, s)
      }
    })(window as any, document as any)
  }, [])

  let rootPath = `/`
  if (typeof __PREFIX_PATHS__ !== `undefined` && (__PREFIX_PATHS__ as any)) {
    rootPath = __PATH_PREFIX__ + `/`
  }

  return (
    <Styled>
      <div className="background">
        <div className="content-wrapper">
          <Navigation location={location} />
          <div className="layout-content">{children}</div>
        </div>
      </div>

      <div className="footer">
        <div className="logo">
          <Logo version="full" />
        </div>
        <div
          className="author"
          style={{ lineHeight: '1', fontSize: '1rem', paddingLeft: '1rem' }}
        >
          {/* Copyright {new Date().getFullYear()} */}
          Web by daytime.studio
        </div>
      </div>
    </Styled>
  )
}
const Styled = styled.div`
  padding-bottom: 12rem;
  font-size: ${DEFAULT_SIZE}rem;

  .background {
    background-color: white;
  }

  .content-wrapper {
    margin: 0 auto;
  }

  .layout-content {
    position: relative;
    width: 100%;
    overflow: auto;
    z-index: 1;
    background-color: white;
  }

  .footer {
    position: fixed;
    bottom: 0;
    z-index: -4;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 4rem 2rem 2rem 2rem;
    color: rgba(0, 0, 0, 0.5);
    background-color: rgba(0, 0, 0, 0.04);

    .logo {
      width: 16rem;
    }

    div {
      display: flex;
      align-items: flex-end;
    }
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: ${MOBILE_SIZE}rem;

    .footer {
      padding: 2rem 1rem 1rem 1rem;
      justify-content: flex-end;
      flex-direction: column;

      .logo {
        width: 12rem;
        padding-bottom: 1rem;
      }
    }

    .layout-content {
      line-height: 1.4;
    }
  }

  @media only screen and (min-width: ${XL_BREAKPOINT}px) {
    .content-wrapper {
      width: ${XL_BREAKPOINT}px;
    }
  }
`

export default Template

import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { DEFAULT_SIZE, MOBILE_BREAKPOINT, BLACK } from '../Utils'
import Logo from './logo'
import MenuIcon from './MenuIcon'
import useWindowSize from '../hooks/useWindowSize'

export default ({ location }: { location: Location }) => {
  const [open, setOpen] = useState(false)
  const [width] = useWindowSize()

  useEffect(() => {
    open && width < MOBILE_BREAKPOINT
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'auto')
  }, [open])

  useEffect(() => {
    const resizeHandler = () => {
      if (width > MOBILE_BREAKPOINT) {
        setOpen(false)
        document.body.style.overflow = 'auto'
      }
    }

    window.addEventListener('resize', resizeHandler)
    return () => window.removeEventListener('resize', resizeHandler)
  }, [])

  return (
    <Styles>
      <nav className="wrapper" role="navigation">
        <Link style={{ lineHeight: 0 }} to="/">
          <div
            onClick={() => setOpen(false)}
            style={{
              zIndex: 10,
              position: open ? 'fixed' : 'relative',
              padding: open ? '1rem 1rem 0 1rem' : '',
              top: open ? '-1.4px' : 0, // Don't ask.
              left: 0,
            }}
            className="logo"
          >
            <div
              style={{
                width: '14rem',
                minWidth: '14rem',
                paddingBottom: '8px',
              }}
            >
              <Logo version="full" />
            </div>
            <img className="celebration" src={'/25years.png'} alt="Logo" />
          </div>
        </Link>

        <div
          style={{ zIndex: 10, paddingBottom: '8px' }}
          onClick={() => setOpen((prev) => !prev)}
          className="mobile"
        >
          <MenuIcon open={open} />
        </div>

        <div
          style={{ zIndex: 9, paddingBottom: '8px' }}
          className={`${open ? 'open' : ''} navigation-wrapper fullscreen`}
        >
          <div className="navigation">
            {[
              ['Projects', '/projects'],
              ['Collaborators', '/collaborators'],
              ['About', '/about'],
              ['Services', '/services'],
              ['Team', '/team'],
              ['Testimonials', '/testimonials'],
              ['Contact', '/contact'],
            ].map(([title, link]) => (
              <div
                style={{
                  color: location.pathname === link ? BLACK : 'inherit',
                }}
                onClick={() => setOpen(false)}
                className="navigationItem"
              >
                <Link to={link}>{title}</Link>
              </div>
            ))}
          </div>
        </div>
      </nav>
    </Styles>
  )
}

const Styles = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 2rem 1rem 0 1rem;
  height: 6rem;
  position: relative;

  .open {
    display: block !important;
  }

  .mobile {
    display: none;
  }

  .fullscreen {
    display: block;
  }

  .wrapper {
    /* display: grid;
    grid: 1fr / 1fr 2fr; */

    display: flex;
    flex-direction: row;

    & > :nth-child(1) {
      width: 33.333%;
    }

    & > :nth-child(2) {
      width: 66.666%;
    }

    align-items: flex-end;
    width: 100%;
    height: 3.8rem;
  }

  .logo {
    padding: 0 1rem;
    position: relative;
    display: flex;
    align-items: flex-end;
    animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    flex-direction: row;
  }

  .celebration {
    border-left: solid rgb(167, 169, 172) 1px;
    padding: 8px;
    margin-left: 16px;
    height: 73.3833px;
    object-fit: cover;
    vertical-align: bottom;
  }

  .navigation-wrapper {
    padding-left: 1rem;
    /* margin-left: 33.333%; */
    /* justify-content: center; */
  }

  @keyframes slide-in-right {
    0% {
      transform: translateX(40px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  .navigation {
    display: flex;
    flex-direction: row;
    margin: 0;
    width: 50%;
    padding: 0;

    & > :first-child {
      margin-left: 0;
    }
  }

  .navigationItem {
    /* display: inline-flex; */
    align-items: center;
    padding-right: 1rem;
    line-height: 0.8;
    /* width: 33.333%; */
  }

  .navigationItem a {
    color: currentColor;
    text-decoration: none;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 1rem 1rem 0 1rem;

    .fullscreen {
      display: none;
    }

    .logo {
      width: 12rem;
      padding: 0%;
    }

    .show {
      display: flex;
    }

    .mobile {
      width: auto !important;
      display: flex;
      align-items: flex-end;
      position: fixed;
      right: 1rem;
      height: 3.8rem;
    }

    .wrapper {
      align-items: flex-end;
    }

    .navigation {
      display: flex;
      width: 100%;
      height: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      & > * {
        margin-left: 0;
      }
    }
    .navigationItem {
      padding: 0.5rem 0;
      font-size: ${DEFAULT_SIZE}rem;
    }
    .navigation-wrapper {
      margin-left: 0;
      display: none;
      position: fixed;
      align-items: center;
      justify-content: center;
      width: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 10;
      background-color: white;
      padding: 1rem;

      /* justify-content: center; */
    }
  }
`

import React from 'react'
import styled from 'styled-components'
import Img, { FluidObject } from 'gatsby-image'

import { MOBILE_BREAKPOINT, BLACK } from '../Utils'

const Base = styled.div`
  padding: 4rem 1rem 2rem 1rem;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 4rem 0rem 2rem 0rem;
  }
`

// export const Content = styled(Base)`
//   display: grid;
//   grid: 1fr / 1fr 2fr;

//   /* column-gap: 2rem; */

//   & > * {
//     color: ${BLACK};
//   }

//   & > :nth-child(1) {
//     padding: 1rem;
//   }

//   & > :nth-child(2) {
//     text-align: justify;
//     /* text-align: left; */
//     padding: 1rem;
//   }

//   @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
//     grid: 0fr / 1fr;
//     & > :nth-child(2) {
//       text-align: left;
//       padding: 0 1rem;
//     }
//   }
// `

export const Content = styled(Base)`
  /* display: grid;
  grid: 1fr / 1fr 2fr; */
  display: flex;
  /* flex-wrap: wrap; */
  flex-direction: row;

  & > * {
    color: ${BLACK};
  }

  & > :nth-child(1) {
    width: 33.333%;
    padding: 0 1rem;
  }

  & > :nth-child(1) > * {
    margin-top: 0rem;
  }

  & > :nth-child(2) {
    width: 66.666%;
    text-align: justify;
    /* text-align: left; */
    padding: 0 1rem;
    margin-top: 0rem;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-direction: column;
    grid: 0fr / 1fr;
    & > :nth-child(1) {
      width: 100%;
      padding: 1rem;
    }

    & > :nth-child(2) {
      width: 100%;
      text-align: left;
      padding: 0 1rem;
    }
  }
`

// export const ContentFull = styled(Base)`
//   /* margin: 1rem; */
// `

// export const ContentThird = styled(Base)`
//   display: grid;
//   grid: 1fr / 1fr 1fr 1fr;

//   @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
//     padding-left: 0;
//   }
// `

export const ImgWithEffect = ({
  fluid,
}: {
  fluid: FluidObject | FluidObject[]
}) => (
  <StyledImageEffect>
    <div className="wrapper">
      <Img className="transition" fluid={fluid} />
    </div>
  </StyledImageEffect>
)

const StyledImageEffect = styled.div`
  .transition {
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    display: block;
    width: 100%;
  }

  .transition:hover {
    transform: scale(1.03);
  }

  .wrapper {
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    display: block;
  }
`

import { useState, useEffect } from 'react'

const safeWindow = (key: keyof Window) =>
  typeof window !== 'undefined' ? window[key] : null

const useWindowSize = () => {
  const [size, setSize] = useState<[number, number]>([
    safeWindow('innerWidth'),
    safeWindow('innerHeight'),
  ])

  useEffect(() => {
    const resizeHandler = () => {
      setSize([window.innerWidth, window.innerHeight])
    }

    window.addEventListener('resize', resizeHandler)
    return () => window.removeEventListener('resize', resizeHandler)
  }, [])

  return size
}

export default useWindowSize

export const CONTAINER_WIDTH = 60

export const DEFAULT_SIZE = 1.12
export const MOBILE_SIZE = DEFAULT_SIZE * 0.92

export const BLUE = 'rgb(0, 157, 213)'
export const GRAY = `rgb(144, 144, 144)`
export const BLACK = `rgba(49, 49, 49, 1)`

export const MOBILE_BREAKPOINT = 960
export const LARGE_BREAKPOINT = 1450
export const XL_BREAKPOINT = 1800

export const prev = (slugs: string[], slug: string) => {
  const currentIndex = slugs.findIndex((s) => s === slug)
  return slugs[
    (currentIndex === 0 ? slugs.length - 1 : currentIndex - 1) % slugs.length
  ]
}

export const next = (slugs: string[], slug: string) =>
  slugs[(slugs.findIndex((s) => s === slug) + 1) % slugs.length]
